<template>
  <v-app>
    <Navbar/>
    <SubTitleNav/>
    <v-main>
      <router-view :key="$route.path" />
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
//import { mapActions } from "vuex";
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import SubTitleNav from "@/components/SubTitleNav.vue";
export default {
  name: "App",
  components: {
    Navbar,
    Footer,
    SubTitleNav
  },
  methods: {
    //...mapActions(["fetchUser"])
  },
  async created() {
    //await this.fetchUser();
  }
};
</script>
