import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "Home" */ "../views/Home.vue")
  },
  {
    path: "/matches",
    name: "Matches",
    component: () =>
      import(/* webpackChunkName: "Home" */ "../views/Matches.vue")
  },
  {
    path: "/teams",
    name: "Teams",
    // route level code-splitting
    // this generates a separate chunk (teams.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/Teams.vue"),
    children: [
      {
        path: "/create",
        name: "Create Team",
        component: () =>
          import(/* webpackChunkName: "Teams" */ "../views/Team.vue"),
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: "/mymatches",
    name: "My Matches",
    component: () =>
      import(/* webpackChunkName: "Home" */ "../views/Matches.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/myteams",
    name: "My Teams",
    // route level code-splitting
    // this generates a separate chunk (teams.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Teams" */ "../views/Teams.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/myseasons",
    name: "My Seasons",
    component: () =>
      import(/* webpackChunkName: "Season" */ "../views/Seasons.vue"),
    meta: {
      requiresAuth: true,
      isAdmin: true
    }
  },
  {
    path: "/myservers",
    name: "My Servers",
    component: () =>
      import(/* webpackChunkName: "Server" */ "../views/Servers.vue"),
    meta: {
      requiresAuth: true,
      isAdmin: true
    }
  },
  {
    path: "/teams/:id",
    name: "Team",
    // route level code-splitting
    // this generates a separate chunk (teams.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Team" */ "../views/Team.vue")
  },
  {
    path: "/match/create",
    name: "New Match",
    // route level code-splitting
    // this generates a separate chunk (teams.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Match" */ "../views/CreateMatch.vue"),
    meta: {
      requiresAuth: true,
      isAdmin: true
    }
    //children: [{ path: "/create", component: MatchCreate }]
  },
  {
    path: "/match/:id",
    name: "Match",
    // route level code-splitting
    // this generates a separate chunk (teams.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Match" */ "../views/Match.vue")
    //children: [{ path: "/create", component: MatchCreate }]
  },
  {
    path: "/user/:id",
    name: "User",
    // route level code-splitting
    // this generates a separate chunk (teams.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "User" */ "../views/User.vue")
    //children: [{ path: "/create", component: MatchCreate }]
  },
  {
    path: "/user",
    name: "My User",
    // route level code-splitting
    // this generates a separate chunk (teams.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "User" */ "../views/User.vue")
    //children: [{ path: "/create", component: MatchCreate }]
  },
  {
    path: "/seasons",
    name: "Seasons",
    // route level code-splitting
    // this generates a separate chunk (teams.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Season" */ "../views/Seasons.vue")
  },
  {
    path: "/season/:id",
    name: "Season",
    // route level code-splitting
    // this generates a separate chunk (teams.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Season" */ "../views/Season.vue")
  },
  {
    path: "/servers",
    name: "Servers",
    // route level code-splitting
    // this generates a separate chunk (teams.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Server" */ "../views/Servers.vue"),
    meta: {
      requiresAuth: true,
      isAdmin: true
    }
  },
  {
    path: "/metrics",
    name: "Metrics",
    // route level code-splitting
    // this generates a separate chunk (teams.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Metrics" */ "../views/Metrics.vue"),
    meta: {
      requiresAuth: true,
      isAdmin: true
    }
  },
  {
    path: "/leaderboard",
    name: "Leaderboard",
    // route level code-splitting
    // this generates a separate chunk (teams.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "Leaderboards" */ "../views/PlayerLeaderboard.vue"
      )
  },
  {
    path: "/ranking",
    name: "Ranking",
    // route level code-splitting
    // this generates a separate chunk (teams.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Leaderboards" */ "../views/Ranking.vue")
  },
  // TODO: Fix this broken route? Child doesn't load...
  {
    path: "/leaderboard/teams",
    name: "TeamBoard",
    // route level code-splitting
    // this generates a separate chunk (teams.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "Leaderboards" */ "../views/TeamLeaderboard.vue"
      )
  },
  {
    path: "/leaderboard/:seasonid",
    name: "SeasonPlayerBoard",
    // route level code-splitting
    // this generates a separate chunk (teams.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Match" */ "../views/PlayerLeaderboard.vue")
    //children: [{ path: "/create", component: MatchCreate }]
  },
  {
    path: "/leaderboard/teams/:seasonid",
    name: "SeasonTeamBoard",
    // route level code-splitting
    // this generates a separate chunk (teams.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "Leaderboards" */ "../views/TeamLeaderboard.vue"
      )
  },
  {
    path: "/lobby",
    name: "Lobby",
    // route level code-splitting
    // this generates a separate chunk (teams.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Leaderboards" */ "../views/Lobby.vue")
  },
  {
    path: "/skins",
    name: "SkinChanger",
    // route level code-splitting
    // this generates a separate chunk (teams.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Leaderboards" */ "../views/SkinChanger.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/plans",
    name: "BuyVip",
    // route level code-splitting
    // this generates a separate chunk (teams.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Leaderboards" */ "../views/BuyVip.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/live/matches",
    name: "CurrentMatches",
    // route level code-splitting
    // this generates a separate chunk (teams.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "Leaderboards" */ "../views/CurrentMatchs.vue"
      )
  },
  {
    path: "/discord",
    beforeEnter() {
      window.location.href = "https://discord.gg/thV7RsDRHF";
    }
  },
  {
    path: "/whatsapp",
    beforeEnter() {
      window.location.href =
        "https://whatsapp.com/channel/0029VafORtvIyPtMEAGyh408";
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  var isAuthenticated = store.getters.isAuthenticated;

  if (!isAuthenticated) {
    try {
      await store.dispatch("fetchUser");
    } catch (error) {
      console.error("Failed to fetch user data:", error);
    }
  }
  const isAdmin = store.getters.isAdmin || store.getters.isSuperAdmin;
  isAuthenticated = store.getters.isAuthenticated;

  console.log("isAuthenticated", isAuthenticated);
  console.log("isAdmin", isAdmin);

  const apiUrl = process.env?.VUE_APP_G5V_API_URL || "/api";

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      window.location.href = `${apiUrl}/auth/steam`;
    } else if (to.matched.some(record => record.meta.isAdmin) && !isAdmin) {
      next({
        path: "/"
        //query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
